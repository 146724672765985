import React, { useState, useEffect } from "react";

const VideoPlayer = ({ url, autoplay = true }) => {
  const [isLive, setIsLive] = useState(false);
  const [title, setTitle] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Extract video ID from URL
    const videoId = extractVideoId(url);
    if (!videoId) return;

    // Load YouTube API
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      new window.YT.Player("youtube-player", {
        height: "360",
        width: "640",
        videoId: videoId,
        playerVars: {
          autoplay: autoplay ? 1 : 0,
          mute: autoplay ? 1 : 0, // Mute the video if autoplay is enabled (required by most browsers)
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };

    // Blink effect for live icon
    const blinkInterval = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 500);

    //
    const onPlayerReady = (event) => {
      const playerInfo = event.target.getVideoData();
      setTitle(playerInfo.title);
      checkIfLive(event.target);
    };

    const onPlayerStateChange = (event) => {
      checkIfLive(event.target);
    };

    return () => clearInterval(blinkInterval);
  }, [url, autoplay]);

  const extractVideoId = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
  };

  const checkIfLive = (player) => {
    const duration = player.getDuration();
    setIsLive(duration === 0);
  };

  return (
    <div className="Live">
      <div id="youtube-player"></div>
      <div className="video-info">
        <h2>{title}</h2>
        {isLive && (
          <div className="live-icon">
            LIVE
            <span
              className={`live-circle ${isVisible ? "visible" : ""}`}
            ></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
