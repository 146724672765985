import React, { useState, useEffect } from "react";

const PlaceHolderVideoPlayer = () => {
  // eslint-disable-next-line no-unused-vars
  const [isLive, setIsLive] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isLive) {
      const interval = setInterval(() => {
        setIsVisible((prev) => !prev);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isLive]);

  return (
    <div className="placeholder-player">
      Video Player Placeholder
      {isLive && (
        <div className="live-icon">
          LIVE
          <span className={`live-circle ${isVisible ? "visible" : ""}`}></span>
        </div>
      )}
    </div>
  );
};

export default PlaceHolderVideoPlayer;
