import "./Welcome.css";
import React from "react";

const Welcome = () => {
  return (
    <div className="welcome">
      {/* <img src="/blank.png" alt="Logo" className="silhouette" /> */}
      <img width="740px" src="/ascii.png" alt="Ascii" className="ascii" />
    </div>
  );
};

export default Welcome;
