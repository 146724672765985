import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import "./App.css";
import Live from "./Live";
import Music from "./Music";
import Welcome from "./Welcome";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="logo-space">
          <span className="logo-text left">nmR.</span>
          <a href="/">
            <img src="/logo.png" alt="Logo" className="logo" />
          </a>
          <span className="logo-text right"></span>
        </div>
        <header className="App-header">
          <Link to="/live" className="nav-link">
            <h1>live.</h1>
          </Link>
          <Link to="/music" className="nav-link">
            <h1>sc.</h1>
          </Link>
        </header>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/live" element={<Live />} />
          <Route path="/music" element={<Music />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
