import SoundCloudEmbed from "./components/SoundCloudEmbed";
import "./Music.css";

function Music() {
  return (
    <div className="content-container">
      <div className="tracks-list">
        <h2>music</h2>
        <SoundCloudEmbed
          url="https://soundcloud.com/nm-radio/nm-live-1"
          playerStyle="1"
        />
      </div>
    </div>
  );
}

export default Music;
