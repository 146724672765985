import "./Live.css";
import PlaceHolderVideoPlayer from "./components/PlaceHolderVideoPlayer";
import VideoPlayer from "./components/VideoPlayer";

function Live() {
  // const url = "";
  const url = "https://youtu.be/FlqOlZPNIjM";

  const hasUrl = url !== "";

  return (
    <div className="player-container">
      <div className="video-player">
        {hasUrl ? <VideoPlayer url={url} /> : <PlaceHolderVideoPlayer />}
      </div>
    </div>
  );
}

export default Live;
