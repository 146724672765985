import React, { useRef } from "react";

const SoundCloudEmbed = ({ url, playerStyle = "1" }) => {
  const iframeRef = useRef(null);
  const isVisual = parseInt(playerStyle, 10) === 2;

  const trackName = url.split("/").pop() || "SoundCloud Track";

  const embedUrl = `https://w.soundcloud.com/player/?url=${encodeURIComponent(
    url
  )}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true${
    isVisual ? "&visual=true" : ""
  }`;

  return (
    <div className="embed-soundcloud-track">
      <div style={{ width: "100%" }}>
        <iframe
          ref={iframeRef}
          width="100%"
          height={isVisual ? "300" : "166"}
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src={embedUrl}
          title={`SoundCloud: ${trackName}`}
        ></iframe>
        <div
          style={{
            fontSize: "10px",
            color: "#cccccc",
            lineBreak: "anywhere",
            wordBreak: "normal",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontFamily:
              "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
            fontWeight: 100,
          }}
        >
          <a
            href="https://soundcloud.com/nm-radio"
            title="NM radio ###"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            NM radio ###
          </a>{" "}
          ·{" "}
          <a
            href={url}
            title={trackName}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#cccccc", textDecoration: "none" }}
          >
            {trackName}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SoundCloudEmbed;
